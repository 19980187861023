import { useRouter } from 'next/router';
import { Typography, Grid, Box, CircularProgress } from '@material-ui/core';
import QuestionCard from './QuestionCard';
import { useTranslation } from "react-i18next";
import styles from '../../styles/QuestionCards.module.css';

export default function QuestionCards({ questions, addToFavorites, ...props }) {
    const router = useRouter();
    const [t,] = useTranslation();

    // when clicking a row
    const onClick = (e, q) => {
        e.preventDefault();
        router.push('/questions/' + q.id);
    };

    return (
        <div>
            <Grid
                container
                wrap="wrap"
                spacing={3}
            >
                {questions ? questions.map((q) =>
                    <Grid
                        item
                        key={q.id}
                        xs={12} sm={6} lg={4}
                        onClick={(e) => onClick(e, q)}
                        href={'/questions/' + q.id}
                        className={styles.clickable}
                        component='a'
                        display='block'
                    >
                        <QuestionCard
                            addToFavorites={addToFavorites}
                            favoriteSnackbar={props.favoriteSnackbar}
                            setFavoriteSnackbar={props.setFavoriteSnackbar}
                            {...q}
                        />
                    </Grid>
                ) :
                    <Grid item xs={12}>
                        <Box width="100%" display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    </Grid>
                }
            </Grid>
            <Typography variant="body2" component="p">
                {t('homePage.latestAnsweredQuestions')}
            </Typography>
        </div>
    );
}