import { Grid } from '@material-ui/core';
import PrimaryCard from './PrimaryCard';
import { getPrimaryCardData } from '../../src/dataProvider';

export default function PrimaryCards() {
    let cards = getPrimaryCardData();
    return (
        <Grid
            container
            justify="space-between"
            spacing={5}
        >
            {cards.map((c) =>
                <Grid key={c.id} item xs={12} md={6}>
                    <PrimaryCard title={c.title} content={c.content} />
                </Grid>
            )}
        </Grid>
    );
}