import { Typography, Box } from '@material-ui/core';

export default function PrimaryCard(props) {
    return (
        <Box height="100%" borderRadius={10} boxShadow={3} p={2}>
            <Typography variant="body1" component="h2">
                <Box fontWeight="fontWeightBold" component="span">
                    {props.title}
                </Box>
            </Typography>
            <Typography variant="body1" align="justify">
                {props.content}
            </Typography>
        </Box>
    );
}