import { Box } from '@material-ui/core';
import styles from '../../styles/ImageContainer.module.css';

export default function Home(props) {
    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <img className={styles.image} src={props.src} alt={props.alt} />
        </Box>
    );
}




