import { Typography, Box } from '@material-ui/core';
import styles from '../../styles/TitledText.module.css';

export default function TitledText(props) {
    return (
        <Typography align={props.justify ? 'justify' : 'left'} variant="body1" component="div" className={styles.container}>
            <Box fontWeight="fontWeightBold" component="span">
                {props.title}
            </Box>
            {props.children}
        </Typography>
    );
}




