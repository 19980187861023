import { useEffect, useState } from 'react';
import { Typography, Grid, Box, Container, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { addFavoriteCard } from "../src/favoriteCards";
import { useTranslation } from "react-i18next";
import PrimaryCards from '../components/cards/PrimaryCards';
import QuestionCards from '../components/cards/QuestionCards';
import ImageContainer from '../components/images/ImageContainer';
import { getRecentQuestions } from '../src/dataProvider';


export async function getStaticProps() {
    return {
        props: {},
    };
}

export default function Home() {
    const [t, i18n] = useTranslation();
    const [questions, setQuestions] = useState();
    const [favoriteSnackbar, setFavoriteSnackbar] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
        severity: "error"
    });

    let vertical = favoriteSnackbar.vertical;
    let horizontal = favoriteSnackbar.horizontal;
    let openSnackbar = favoriteSnackbar.open;

    useEffect(() => {
        let mounted = true;
        const runEffect = async () => {
            const r = await getRecentQuestions(i18n.language);
            if (mounted) {
                setQuestions(r);
            }
        };
        runEffect();
        return () => { mounted = false; };
    }, [i18n.language]);

    const handleCloseSnackbar = (e) => {
        if (e) {
            e.stopPropagation(); //dont activate redirect
        }
        setFavoriteSnackbar({ ...favoriteSnackbar, open: false });
    };

    let addToFavorites = (id, favFlag) => {
        const makeRequests = async () => {
            const exists = await addFavoriteCard(id, favFlag, i18n.language);
            const r = await getRecentQuestions(i18n.language);
            setQuestions(r);
            return exists;
        };
        return makeRequests();
    };

    return (
        <Container maxWidth="xl">
            <Box mb={2}>
                <Typography variant="h5" component="h1">
                    {t('homePage.title')}
                </Typography>
            </Box>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <PrimaryCards />
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={3}
                        justify="space-between"
                    >
                        <Grid item xs={12} md={8}>
                            <Snackbar
                                onClose={handleCloseSnackbar}
                                autoHideDuration={1500}
                                anchorOrigin={{ vertical, horizontal }}
                                open={openSnackbar}
                                key={vertical + horizontal}
                            >
                                <Alert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={favoriteSnackbar.severity}>
                                    {favoriteSnackbar.message}
                                </Alert>
                            </Snackbar>
                            <QuestionCards
                                questions={questions}
                                addToFavorites={addToFavorites}
                                favoriteSnackbar={favoriteSnackbar}
                                setFavoriteSnackbar={setFavoriteSnackbar}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <ImageContainer src="/static/home.jpg" alt={t('aria.homeImg')} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

