import { getQuestionsByIds, getFavorites, deleteFavorite, postFavorite } from './dataProvider';

export async function getFavoriteCards(lang, filters) {
    let favoriteCards = [];
    let ids = [];

    ids = (await getFavorites(lang) || []).map((f) => f.id);

    let questions;
    if (ids.length > 0) questions = await getQuestionsByIds(lang, ids, filters?.category, filters?.section, filters?.keywords, filters?.startdate, filters?.enddate);
    if (questions) {
        favoriteCards = questions.map((q) => ({
            id: q.id,
            title: q.title,
            question: q.question,
            answer: q.answer
        }));
    }
    return favoriteCards;
}

export async function deleteFavoriteCard(id) {
    await deleteFavorite(id);
}

export async function addFavoriteCard(id, favFlag) {
    if (favFlag) {
        return true; //already exists
    } else {
        await postFavorite(id);
        return false; //does not already exist
    }
}